export const MstrApiService = {
  get: async (url: string) =>
    fetch(`${url}`, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: { "content-type": "application/json" },
    }),

  post: async (url: string, payload?: any, headers?: HeadersInit) =>
    fetch(`${url}`, {
      method: "POST",
      credentials: "include",
      mode: "cors",
      body: JSON.stringify(payload),
      headers: headers ?? { "content-type": "application/json" },
    }),
};

export const UserSettingsApiService = {
  get: async (url: string, token?: string) =>
    fetch(`${url}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "content-type": "application/json",
        Authorization: token ?? "",
      },
    }),
  post: async (url: string, body: any, token?: string) =>
    fetch(`${url}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json",
        Authorization: token ?? "",
      },
      body: JSON.stringify(body),
    }),
};
