import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { pendoFeatureFlag } from "../../utility/launchDarklyFlagHelper";
import Loader from "../controls/loader/Loader";
import { LoaderMessages } from "../../utility/Messages";
import { PendoAccount, PendoVisitor } from "../../model/pendoModel";
import { currentEnvironment } from "../../environments/environments";
import { initializePendo } from "../../pendo";
import { savePendoModel } from "../../services/pendoService";
import DashboardPage from "./DashboardPage";
import ReportPage from "./ReportPage";
import useCustomSearchParams from "../../utility/useCustomSearchParam";
import { getAccount } from "../../services/userSettingsService";
import { UserAccount } from "../../model/userAccountModel";
import { LogType } from "../../enum";
import { logService } from "../../services/logService";
import { getBridgeUserModel } from "../../utility/sessionStorageHelper";

function VisualPage() {
  const { pathname } = useLocation();
  const mstrLink = useCustomSearchParams("origCtx");
  const bookmarkIds = useCustomSearchParams("ids");
  const launchDarklyFlags = useFlags();
  const launchDarklyClient = useLDClient();
  const [flagLoaded, setFlagLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (mstrLink) {
      const libraryUrlParts = mstrLink.split("/");
      const reportId =
        libraryUrlParts.length > 3 ? libraryUrlParts[3] : undefined;
      let mstrEmbeddingLink = mstrLink.substring(mstrLink.indexOf("/") + 1);
      if (bookmarkIds)
        mstrEmbeddingLink = `${mstrEmbeddingLink}?ids=${bookmarkIds}`;
      if (reportId) {
        const route = `report?id=${reportId}&mstrLink=${mstrEmbeddingLink}`;
        navigate(`/${route}`);
      } else {
        navigate("/");
      }
    }
  }, [mstrLink, bookmarkIds, navigate]);

  useEffect(() => {
    if (launchDarklyClient) {
      launchDarklyClient
        .waitUntilReady()
        .then(() => setFlagLoaded(true))
        .catch((err) => {
          logService.log({
            message: `Error while initializing the launch darkly: ${err}`,
            type: LogType.Error,
            method: "VisualPage",
            file: "VisualPage.tsx",
          });
          setFlagLoaded(false);
        });
    }
  }, [launchDarklyClient]);

  useEffect(() => {
    initPendo();
    function initPendo() {
      if (flagLoaded && launchDarklyFlags[pendoFeatureFlag]) {
        const user = getBridgeUserModel();
        if (!user) return;
        const names = user.name ? user.name.split(" ") : [];
        const firstName =
          names.length > 1
            ? names.slice(0, names.length - 1).join(" ")
            : user.name;
        const lastName = names.length > 1 ? names[names.length - 1] : undefined;
        const pendoVisitor: PendoVisitor = {
          id: user.sub,
          user_first_name: firstName,
          user_last_name: lastName,
          bridge_user_name: user.userName,
          product: currentEnvironment().customConfiguration.product,
        };
        let pendoAccount: PendoAccount | undefined = undefined;
        const userAccount: UserAccount | undefined = getAccount();
        if (userAccount && userAccount.sources.length > 0) {
          pendoAccount = {
            account_id: userAccount.sources
              .map((s) => s.commonOrgEntity?.id ?? "")
              .join(","),
            account_name: userAccount.sources
              .map((s) => s.commonOrgEntity?.dbaName ?? "")
              .join(","),
            associated_application: "RAD",
          };
        }

        savePendoModel({
          visitor: pendoVisitor,
          account: pendoAccount,
        });

        initializePendo(pendoVisitor);
      }
    }
  }, [launchDarklyFlags, flagLoaded]);

  if (mstrLink) return <Loader msg={LoaderMessages.mstrSession} />;

  if (!flagLoaded) return <Loader msg={LoaderMessages.mstrSession} />;

  if (pathname.indexOf("home") !== -1) return <DashboardPage></DashboardPage>;
  return <ReportPage></ReportPage>;
}

export default VisualPage;
