import { LogType, MstrMessageType } from "../enum";
import { MstrMessage, PendoMstrMessage } from "../model/mstrMessageModel";
import { PendoModel } from "../model/pendoModel";
import {
  setPendoModel,
  getPendoModel as getPendoModelFromSession,
  clearPendoModel as clearPendoModelFromSession,
} from "../utility/sessionStorageHelper";
import { logService } from "./logService";
import { postMessageToDossier } from "./mstrMessagingSerive";

export function savePendoModel(pendoModel: PendoModel) {
  setPendoModel(pendoModel);
}

export function getPendoModel(): PendoModel | undefined {
  return getPendoModelFromSession();
}

export function clearPendoModel() {
  clearPendoModelFromSession();
}

export const postPendoMessageToDossier = (id: string) => {
  try {
    const pendoModel = getPendoModel();
    if (!pendoModel) return;
    const mstrMessage: MstrMessage = {
      type: MstrMessageType.Pendo,
      message: pendoModel as PendoMstrMessage,
    };
    if (pendoModel) postMessageToDossier(id, mstrMessage);
  } catch (error) {
    logService.log({
      message: `Error while sending pendo initialization message to the dossier : ${error}`,
      type: LogType.Error,
      method: "postPendoMessageToDossier",
      file: "pendoService.ts",
    });
  }
};
