import {
  MSTR_ACCOUNTING_DASHBOARD_ID,
  MSTR_ACCOUNTING_REPORT_ID,
  MSTR_PROJECT_ID,
} from "../config/mstrConfig";
import { MstrMessageType } from "../enum";
import {
  AccountingSummaryMstrMessage,
  MstrMessage,
} from "../model/mstrMessageModel";
import { getUserMstrId } from "../utility/sessionStorageHelper";
import { postMessageToDossier } from "./mstrMessagingSerive";

export const postAccountingToggleFilterSummaryMessageToDossier = (
  id: string,
  isReport: boolean
) => {
  const userId = getUserMstrId();
  if (!userId) return;
  const accountingSummaryMstrMessage: AccountingSummaryMstrMessage = {
    userId: userId,
    projectId: MSTR_PROJECT_ID,
    accountingToBeSimulatedId: isReport
      ? MSTR_ACCOUNTING_REPORT_ID
      : MSTR_ACCOUNTING_DASHBOARD_ID,
    accountingToBeUpdatedId: isReport
      ? MSTR_ACCOUNTING_DASHBOARD_ID
      : MSTR_ACCOUNTING_REPORT_ID,
  };
  const mstrMessage: MstrMessage = {
    type: MstrMessageType.AccountingSummaryToggle,
    message: accountingSummaryMstrMessage,
  };
  postMessageToDossier(id, mstrMessage);
};
