import "./NavBar.scss";
import NavBarItem from "../navBarItem/NavBarItem";
import {
  getReports,
  getUserReportByReportId,
} from "../../../services/userSettingsService";
import { UserReport } from "../../../model/userReportsModel";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomSearchParams from "../../../utility/useCustomSearchParam";
import { ErrorMessages } from "../../../utility/Messages";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getLaunchDarklyFlagKeyFromReportName } from "../../../utility/launchDarklyFlagHelper";
import { AdminUserContext } from "../../../contexts";
import getError from "../../../utility/errorHelper";

type NavBarData = { id: string; text: string };

function NavBar() {
  const navigate = useNavigate();
  const reportId = useCustomSearchParams("id");
  const launchDarklyFlags = useFlags();
  const isAdminUser = useContext(AdminUserContext);
  const userReports = getReports();
  const [selectedNavItem, setSelectedNavItem] = useState<string>();

  const isSelected = useCallback(
    (id: string, selectedId: string | undefined) => id === selectedId,
    []
  );

  const getSubMenuItems = useCallback(
    (items: NavBarData[], groupName: string): NavBarData[] | undefined => {
      if (!items?.length) {
        return;
      }

      if (items.length === 1 && items[0].text === groupName) {
        return undefined;
      }

      return items;
    },
    []
  );

  const generateNavMenuItems = (userReports: UserReport[]) => {
    const reportMap = new Map<string, NavBarData[]>();
    reportMap.set("Home", []);
    userReports.forEach((u) => {
      if (
        isAdminUser ||
        launchDarklyFlags[getLaunchDarklyFlagKeyFromReportName(u.reportName)]
      ) {
        if (!reportMap.has(u.reportGroupName)) {
          reportMap.set(u.reportGroupName, []);
        }

        reportMap
          .get(u.reportGroupName)!
          .push({ id: u.reportId, text: u.reportName });
      }
    });

    let component: React.ReactNode[] = [];
    reportMap.forEach((v, k) => {
      component.push(
        <NavBarItem
          key={k}
          text={k}
          id={v.length === 1 ? v[0].id : k}
          subMenus={getSubMenuItems(v, k)}
          selected={isSelected(k, selectedNavItem)}
        ></NavBarItem>
      );
    });
    return <>{component.map((c) => c)}</>;
  };

  useEffect(() => {
    if (userReports.length === 0) {
      navigate("/error", {
        state: {
          error: getError(ErrorMessages.noReportAccess),
        },
      });
    }
  }, [navigate, userReports.length]);

  useEffect(() => {
    if (!reportId) {
      setSelectedNavItem("Home");
      return;
    }

    const group = getUserReportByReportId(reportId)?.reportGroupName;
    setSelectedNavItem(group);
  }, [reportId]);

  if (userReports.length === 0) return <></>;

  return <nav className="navBar">{generateNavMenuItems(userReports)}</nav>;
}

export default NavBar;
