import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { OneViewEventData } from "../hooks";
import { MstrMessage } from "../model/mstrMessageModel";
import { logService } from "./logService";

export const postMessageToDossier = <T = any>(
  id: string,
  data: MstrMessage | OneViewEventData<T>,
  timeout?: number
) => {
  let count = 0;
  timeout = timeout ?? 100;
  const interval = setInterval(() => {
    try {
      if (++count > 100) {
        clearInterval(interval);
        logService.log({
          message:
            "Unable to send message to dossier as iframe is not loaded even after all retries to check iframe load exausted.",
          type: LogType.Error,
          method: "postMessageToDossier",
          file: "mstrMessagingService.ts",
        });
        return;
      }
      const frame = document.getElementById(id)?.querySelector("iframe");
      if (frame && frame.contentWindow) {
        clearInterval(interval);
        frame.contentWindow.postMessage(
          data,
          currentEnvironment().customConfiguration.mstrUrl
        );
      }
    } catch (err) {
      logService.log({
        message: `${err}`,
        type: LogType.Error,
        method: "postMessageToDossier",
        file: "mstrMessagingService.ts",
      });
    }
  }, timeout);
};
