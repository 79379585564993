import { useEffect, useState } from "react";

export enum OneViewEvent {
  IframeUrlChange = "ONE_VIEW_IFRAME_URL_CHANGED_EVENT",
  HidePageKey = "ONE_VIEW_HIDE_PAGE_KEY_EVENT",
  IframeReady = "ONE_VIEW_IFRAME_READY",
}
export interface OneViewEventData<T = any> {
  type: string;
  payload: T;
}

export const useMessageListener = <T = any>(type: OneViewEvent) => {

  const [payload, setPayload] = useState<T>();
  
  useEffect(() => {
    const listenEvent = (e: MessageEvent<OneViewEventData<T>>) => {

      if(!e?.data?.type) { return; }

      if(type === e.data.type) { 
        setPayload(e.data.payload);
      }
    };

    window.addEventListener("message", listenEvent);

    return () => {
      window.removeEventListener("message", listenEvent);
    };
  }, [type]);

  return payload;

};


