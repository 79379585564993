import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { UserAccount } from "../model/userAccountModel";
import { Page, UserReport } from "../model/userReportsModel";
import {
  setUserReports,
  getUserReports as getUserReportsFromSession,
  clearUserReports as clearUserReportsFromSession,
  setUserAccount,
  getUserAccount as getUserAccountFromSession,
  clearUserAccount as clearUserAccountFromSession,
} from "../utility/sessionStorageHelper";
import { UserSettingsApiService } from "./apiService";
import { logService } from "./logService";

export function InitUserSettings() {
  const environment = currentEnvironment();
  UserSettingsApiService.get(
    `${environment.customConfiguration.userSettingUrl}/init`
  );
}

export async function getUserReports(
  token: string
): Promise<UserReport[] | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/reports`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      logService.log({
        message: `api call to setting api has failed`,
        type: LogType.Error,
        method: "getUserReports",
        file: "userSettingsService.ts",
      });
      return;
    }

    const userReports = (await response.json()) as UserReport[];

    userReports.forEach((report) => {
      if (!restrictedPages.has(report.reportId)) {
        return;
      }

      report.restrictedPages = [...restrictedPages.get(report.reportId)!];
    });

    setUserReports(userReports);

    return userReports;
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getUserReports",
      file: "userSettingsService.ts",
    });
  }
  return undefined;
}

export function getReports(): UserReport[] {
  return getUserReportsFromSession();
}

export function getReportById(id: string): UserReport | undefined {
  const reports = getUserReportsFromSession();
  return reports.find((r) => r.reportId === id);
}

export function clearUserReports() {
  clearUserReportsFromSession();
}

export function getUserReportByReportId(
  reportId: string | null
): UserReport | undefined {
  if (!reportId) {
    return undefined;
  }
  return getReports().find((f) => f.reportId === reportId);
}

export async function getUserAccount(
  token: string
): Promise<UserAccount | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/account`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      logService.log({
        message: `api call to setting api to get user account has failed`,
        type: LogType.Error,
        method: "getUserAccount",
        file: "userSettingsService.ts",
      });
      return;
    }

    const userAccount = (await response.json()) as UserAccount;
    setUserAccount(userAccount);

    return userAccount;
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getUserAccount",
      file: "userSettingsService.ts",
    });
  }
  return undefined;
}

export function getAccount(): UserAccount | undefined {
  return getUserAccountFromSession();
}

export function clearUserAccount() {
  clearUserAccountFromSession();
}

const restrictedPages: Map<string, Page[]> = new Map([
  [
    "B6A42F5E2B48412B514D54BCAFFCA3C4",
    [
      { key: "W217--K46", name: "Hours Sold" },
      { key: "W221--K46", name: "Dollars Sol" },
      { key: "W244--K265", name: "Work In Process" },
    ],
  ],
  [
    "BF4F8FBF334AD793B99AEF80498E533D",
    [
      { key: "W137--K46", name: "Efficiency" },
      { key: "W138--K111", name: "Technician Sales" },
    ],
  ],
  [
    "A412BA867E4181ED104CEF8B88C5355D",
    [
      // { key: "K53--K46", name: "Business Office Summary" },
      { key: "W377--K46", name: "Gross Profit Analysis" },
      { key: "W427--K111", name: "F&I Products" },
      { key: "W381--K166", name: "Wholesale Analysis" },
    ],
  ],
]);
