import DossierContainer from "../../dossier/DossierContainer";
import "./ReportContainer.scss";

export interface ReportContainerProp {
  dossierId: string;
  visualId?: string;
  mstrLink?: string;
}

function ReportContainer(props: ReportContainerProp) {
  return (
    <div className="reportDossier">
      <DossierContainer
        dossierId={props.dossierId}
        visualId={props.visualId}
        size="normal"
        mstrLink={props.mstrLink}
      />
    </div>
  );
}

export default ReportContainer;
