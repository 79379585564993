import React, { useState, useEffect, useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ErrorMessages } from "../../utility/Messages";
import { postPendoMessageToDossier } from "../../services/pendoService";
import { pendoFeatureFlag } from "../../utility/launchDarklyFlagHelper";
import {
  addDossierErrorHandler,
  createDossier,
  createInstanceForAccounting,
  destroyDossier,
  getDossierProperties,
  OneViewDossierConfig,
  registerEvent,
  setVisualizationNormalSizeForReport,
  storeCurrentInstanceId,
} from "../../services/dossierService";
import { DossierEventType, LogType, VisualSize } from "../../enum";
import getError, { ErrorModel } from "../../utility/errorHelper";
import { logService } from "../../services/logService";
import {
  getReportById,
  getUserReportByReportId,
} from "../../services/userSettingsService";
import {
  OneViewEvent,
  OneViewEventData,
  useMessageListener,
} from "../../hooks";
import { postMessageToDossier } from "../../services/mstrMessagingSerive";
import { Page } from "../../model/userReportsModel";

const Dossier: React.FC<any> = ({
  containerId,
  dossierId,
  authToken,
  style,
  pageKey,
  visualId,
  size,
  resizeButtonVisible,
  onGraphicSelected,
  mstrLink,
  postAccountingMessage,
  isAccountingReport,
  isAccountingDashboard,
  isBookmark,
}) => {
  const [error, setError] = useState<ErrorModel | undefined>(undefined);
  const isVisualMaximizedDashboard = size === VisualSize.Maximized;
  const navigate = useNavigate();
  const launchDarklyFlags = useFlags();
  const isIframeReady = useMessageListener<boolean>(OneViewEvent.IframeReady);

  const errorHandler = useCallback((error: any) => {
    logService.log({
      message: `Error in microStrategy report loading : ${error.message} - ${error.desc}`,
      type: LogType.Error,
      method: "errorHandler",
      file: "Dossier.tsx",
    });
    setError(getError(ErrorMessages.mstrReportLoadError));
  }, []);

  const sessionErrorHandler = useCallback((error: any) => {
    logService.log({
      message: `MicroStrategy Session error : ${error.message} - ${error.desc}`,
      type: LogType.Error,
      method: "sessionErrorHandler",
      file: "Dossier.tsx",
    });
    setError(getError(ErrorMessages.sessionError));
  }, []);

  const pageSwitchHandler = useCallback(
    (data: { key: string }) => {
      console.log("Page changed ", data);

      const report = getReportById(dossierId);

      const restrictedPage = report?.restrictedPages?.find(
        (page) => data.key.search(page.key) >= 0
      );
      if (!restrictedPage) {
        return;
      }
      const message = [
        'You do not have access to the "',
        restrictedPage.name,
        '" page of ',
        report!.reportName,
        " report. Please contact administrator.",
      ].join("");
      setError(getError(message));
    },
    [dossierId]
  );

  useEffect(() => setError(undefined), []);

  useEffect(() => {
    if (!error) {
      return;
    }

    const path = "/error";
    const options: NavigateOptions = {
      state: { error },
    };

    navigate(path, options);
  }, [error, navigate]);

  useEffect(() => {
    const placeholder = document.getElementById(containerId);
    if (!placeholder) {
      return;
    }

    initializeDossier(placeholder);
    return cleanup;

    async function initializeDossier(placeholder: HTMLElement) {
      try {
        const config: OneViewDossierConfig = {
          authToken,
          dossierId,
          errorHandler,
          sessionErrorHandler,
          pageKey,
          visualId,
          placeholder,
          isNormalSize: !isVisualMaximizedDashboard,
          isAccountingDashboard,
          isAccountingReport,
          isBookmark,
          mstrLink,
        };

        await CreateInstanceIfRequired(config);
        const dossierProps = getDossierProperties(config);
        const instance = await createDossier(dossierProps);
        setVisualizationNormalSizeForReport(instance, config);
        addDossierErrorHandler(instance, errorHandler);
        registerEvent(
          instance,
          pageSwitchHandler,
          DossierEventType.OnPageChanged
        );

        if (onGraphicSelected)
          registerEvent(
            instance,
            onGraphicSelected,
            DossierEventType.GraphicSelecetd
          );
        performPostInsanceloadOperationsIfRequired(instance, config);
      } catch (error) {
        logService.log({
          message: `Encounter error while initializing the dossier : ${error}`,
          type: LogType.Error,
          method: "initializeDossier",
          file: "Dossier.tsx",
        });
      }

      function performPostInsanceloadOperationsIfRequired(
        instance: any,
        config: OneViewDossierConfig
      ) {
        if (launchDarklyFlags[pendoFeatureFlag]) {
          postPendoMessageToDossier(containerId);
        }
        if ((isAccountingDashboard || isAccountingReport) && !isBookmark) {
          registerEvent(
            instance,
            postAccountingMessage,
            DossierEventType.OnLayoutChanged
          );
          storeCurrentInstanceId(instance, config);
        }
      }

      async function CreateInstanceIfRequired(config: OneViewDossierConfig) {
        if ((isAccountingDashboard || isAccountingReport) && !isBookmark) {
          const instanceId = await createInstanceForAccounting(config);
          config.instanceId = instanceId;
        }
      }
    }

    function cleanup() {
      destroyDossier(placeholder);
    }
  }, [
    dossierId,
    authToken,
    containerId,
    visualId,
    isVisualMaximizedDashboard,
    resizeButtonVisible,
    onGraphicSelected,
    pageKey,
    errorHandler,
    sessionErrorHandler,
    isAccountingDashboard,
    isAccountingReport,
    isBookmark,
    launchDarklyFlags,
    postAccountingMessage,
    mstrLink,
    pageSwitchHandler,
  ]);

  useEffect(() => {
    const report = getUserReportByReportId(dossierId);
    if (!report?.restrictedPages?.length) {
      return;
    }

    if (!isIframeReady) {
      return;
    }

    const message: OneViewEventData<Page[]> = {
      type: OneViewEvent.HidePageKey,
      payload: report.restrictedPages,
    };
    postMessageToDossier<Page[]>(containerId, message);
    
  }, [dossierId, isIframeReady, containerId]);

  if (error) return <></>;

  return (
    <>
      <div
        id={containerId}
        style={{
          display: authToken ? "flex" : "none",
          flexGrow: 1,
          height: "100%",
          ...style,
        }}
        //@ts-ignore
        sandbox="allow-same-origin allow-scripts"
      />
    </>
  );
};

export default Dossier;
