import { useEffect, useContext, useState } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getUserReportByReportId } from "../../services/userSettingsService";
import useCustomSearchParams from "../../utility/useCustomSearchParam";
import ReportContainer from "../visuals/reportContainer/ReportContainer";
import { getLaunchDarklyFlagKeyFromReportId } from "../../utility/launchDarklyFlagHelper";
import { ErrorMessages } from "../../utility/Messages";
import NoAccess from "../controls/noAccess/NoAccess";
import { AdminUserContext } from "../../contexts";
import getError from "../../utility/errorHelper";
import { useCurrentMSTRPage } from "../../hooks";

function ReportPage() {
  const reportId = useCustomSearchParams("id");
  const report = reportId ? getUserReportByReportId(reportId) : undefined;
  const visualId = report?.visualKey;
  const mstrLink = useCustomSearchParams("mstrLink") ?? undefined;
  const navigate = useNavigate();
  const launchDarklyFlags = useFlags();
  const isAdminUser = useContext(AdminUserContext);
  const [pageAccess, setPageAccess] = useState(false);
  const currentPageKey = useCurrentMSTRPage();

  useEffect(() => {
    if (!reportId) {
      navigate("/error", {
        state: {
          error: getError(ErrorMessages.incorrectResource),
        },
      });
    }
  }, [navigate, reportId]);

  useEffect(() => {
    const report = getUserReportByReportId(reportId);

    if (!report?.restrictedPages?.length) {
      setPageAccess(true);
      return;
    }

    const key = currentPageKey || mstrLink || "";

    const restrictedPage = report.restrictedPages?.find(
      (page) => key.search(page.key) >= 0
    );

    if (!restrictedPage) {
      setPageAccess(true);
      return;
    }

    const message = [
      "You do not have access to the ",
      restrictedPage.name,
      " page of ",
      report!.reportName,
      " report. Please contact administrator.",
    ].join("");

    const error = getError(message);
    const path = "/error";
    const options: NavigateOptions = {
      state: { error },
    };
    navigate(path, options);
    return;
  }, [navigate, mstrLink, reportId, currentPageKey]);

  if (!reportId)
    return <NoAccess msg={ErrorMessages.noCurrentReportAccess}></NoAccess>;

  const hasReportAccess =
    getLaunchDarklyFlagKeyFromReportId(reportId) &&
    launchDarklyFlags[getLaunchDarklyFlagKeyFromReportId(reportId)!] &&
    pageAccess;

  if (isAdminUser || hasReportAccess)
    return (
      <ReportContainer
        dossierId={reportId}
        visualId={visualId}
        mstrLink={mstrLink}
      />
    );

  return <NoAccess msg={ErrorMessages.noCurrentReportAccess}></NoAccess>;
}

export default ReportPage;
