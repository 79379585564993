import {
  getLaunchDarklyFlagKeyFromReportName,
  kpiDashboardFlag,
  landingPageFlag,
} from "../../utility/launchDarklyFlagHelper";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getReports } from "../../services/userSettingsService";
import {
  DashboardGroupVisuals,
  KpiDashboardVisual,
} from "../../model/userReportsModel";
import VisualMaximizedDashboardContainer from "../visuals/visualMaximizedDashboardContainer/VisualMaximizedDashboardContainer";
import { ErrorMessages } from "../../utility/Messages";
import KpiDashboardContainer from "../visuals/kpiDashboardContainer/KpiDashboardContainer";
import NoAccess from "../controls/noAccess/NoAccess";
import { useContext } from "react";
import { AdminUserContext } from "../../contexts";

function DashboardPage() {
  const launchDarklyFlags = useFlags();
  const isAdminUser = useContext(AdminUserContext);
  const dashboardType = launchDarklyFlags[kpiDashboardFlag]
    ? "KpiDashboard"
    : "VisualMaximizedDashboard";

  if (!isAdminUser && !launchDarklyFlags[landingPageFlag]) {
    return <NoAccess msg={ErrorMessages.noDashboardAccess}></NoAccess>;
  }

  //VisualMaximizedDashboard
  if (dashboardType === "VisualMaximizedDashboard") {
    const userReports = getReports();
    const dashboardGroupVisuals: DashboardGroupVisuals[] = [];
    userReports.forEach((report) => {
      if (
        report.visualKey &&
        report.pageKey &&
        (isAdminUser ||
          launchDarklyFlags[
            getLaunchDarklyFlagKeyFromReportName(report.reportName)
          ])
      ) {
        const existingGroup = dashboardGroupVisuals.find(
          (dv) => dv.groupName === report.reportGroupName
        );
        if (existingGroup) {
          existingGroup.visuals.push({
            reportId: report.reportId,
            pageKey: report.pageKey,
            visualkey: report.visualKey,
            reportName: report.reportName,
            row: report.visualRows ?? 1,
            col: report.visualCols ?? 1,
          });
        } else {
          dashboardGroupVisuals.push({
            groupName: report.reportGroupName,
            visuals: [
              {
                reportId: report.reportId,
                visualkey: report.visualKey,
                pageKey: report.pageKey,
                reportName: report.reportName,
                row: report.visualRows ?? 1,
                col: report.visualCols ?? 1,
              },
            ],
          });
        }
      }
    });

    if (dashboardGroupVisuals.length === 0) {
      return <NoAccess msg={ErrorMessages.noDashboardAccess}></NoAccess>;
    }

    return (
      <VisualMaximizedDashboardContainer dossiers={dashboardGroupVisuals} />
    );
  }

  //KpiDashboard
  else {
    const dashboards: KpiDashboardVisual[] = [];
    const userReports = getReports();
    userReports.forEach((report) => {
      if (
        report.dashboardId &&
        (isAdminUser ||
          launchDarklyFlags[
            getLaunchDarklyFlagKeyFromReportName(report.reportName)
          ])
      ) {
        const existingDashboard = dashboards.find(
          (dashboard) => dashboard.dashboardId === report.dashboardId
        );
        if (!existingDashboard) {
          dashboards.push({
            groupName: report.reportGroupName,
            dashboardId: report.dashboardId,
          });
        }
      }
    });
    if (dashboards.length === 0) {
      return <NoAccess msg={ErrorMessages.noDashboardAccess}></NoAccess>;
    }
    return (
      <KpiDashboardContainer dashboards={dashboards}></KpiDashboardContainer>
    );
  }
}
export default DashboardPage;
