import { MSTR_PROJECT_ID, X_MSTR_AUTHTOKEN } from "../config/mstrConfig";
import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { MstrApiService } from "./apiService";
import { logService } from "./logService";

const mstrUrl = currentEnvironment().customConfiguration.mstrUrl;

export const getToken = async () => {
  try {
    const response = await MstrApiService.get(`${mstrUrl}/api/auth/token`);

    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }

    const token = response.headers.get(X_MSTR_AUTHTOKEN);
    return token;
  } catch (error) {
    logService.log({
      message: `Error while fetching token from microstrategy - ${error}`,
      type: LogType.Error,
      method: "getToken",
      file: "mstrRestService.ts",
    });
    return null;
  }
};

export const mstrLogout = async () =>
  await MstrApiService.post(`${mstrUrl}/api/auth/logout`);

export const createInstance = async (
  dossierId: string,
  payload: any,
  authToken: string
) => {
  try {
    const headers = {
      "X-MSTR-AuthToken": authToken,
      accept: "application/json",
      "X-MSTR-ProjectID": MSTR_PROJECT_ID,
      "content-type": "application/json",
    };
    const response = await MstrApiService.post(
      `${mstrUrl}/api/dossiers/${dossierId}/instances`,
      payload,
      headers
    );

    if (!response.ok || response.status !== 201) {
      throw new Error(JSON.stringify(response));
    }

    const instanceId = (await response.json()).mid;
    return instanceId;
  } catch (error) {
    logService.log({
      message: `Error in creating instance for dossier ${dossierId}- ${error}`,
      type: LogType.Error,
      method: "createInstance",
      file: "mstrRestService.ts",
    });
    return null;
  }
};

export const invokeMstrLogin = () => {
  const mstr_url_param = "mstr_invoked=true";
  let location = `${window.location.href}`;
  if (!location.includes(mstr_url_param)) {
    location = location.includes("?")
      ? `${location}&${mstr_url_param}`
      : `${location}?${mstr_url_param}`;
  }

  const encodedLocation = encodeURIComponent(location);
  const url = `${mstrUrl}/auth/login-dialog.jsp?loginMode=4194304&callback-origin=${encodedLocation}`;

  window.open(url, "_self");
};

export const getUserInfo = async () => {
  try {
    const response = await MstrApiService.get(
      `${mstrUrl}/api/sessions/userInfo`
    );

    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }

    const userInfo = await response.json();
    return userInfo.id;
  } catch (error) {
    logService.log({
      message: `Error while fetching userinfo from microstrategy- ${error}`,
      type: LogType.Error,
      method: "getUserInfo",
      file: "mstrRestService.ts",
    });
    return null;
  }
};
