import { useEffect, useState } from "react";
import {
  storeCurrentMSTRPage,
  storeCurrentMSTRUrl,
} from "../utility/sessionStorageHelper";

export const useCurrentMSTRPage = (): string => {
  const [currentPageKey, setCurrentPageKey] = useState("");

  useEffect(() => {
    const handleChildMessages = (e: any) => {
      console.log("Message from child iframe", e.data);

      if (e.data.type === "ONE_VIEW_IFRAME_URL_CHANGED_EVENT") {
        const pathname = e.data.payload.url as string;
        const pageKey =
          pathname.split("/").find((section) => section.includes("--")) ?? "";
        storeCurrentMSTRPage(pageKey);
        setCurrentPageKey(pageKey);
        return;
      }

      const { name, value } = e.data;
      if (name === "onEmbedLocationChanged") {
        const pathname = value.pathname as string;
        storeCurrentMSTRUrl(pathname);
        console.log("Child Data for (onEmbedLocationChanged)", pathname);
        const pageKey =
          pathname.split("/").find((section) => section.includes("--")) ?? "";
        storeCurrentMSTRPage(pageKey);
        setCurrentPageKey(pageKey);

        return;
      }

      if (name === "onPageSwitched") {
        console.log("Child Data for (onEmbedLocationChanged)", value.key);
        const pageKey = value.key as string;
        storeCurrentMSTRPage(pageKey);
        setCurrentPageKey(pageKey);
        return;
      }
    };

    window.addEventListener("message", handleChildMessages);

    return () => {
      window.removeEventListener("message", handleChildMessages);
    };
  }, []);

  return currentPageKey;
};
